<template>
    <div>
        <van-popup
            class="city-popup"
            v-model="popShow2"
            position="bottom"
            @click-overlay="cancelFun"
        >
            <div>
                <div class="f16 fw7 pl12 mt25">选择{{ tyoeObj[type] }}</div>
                <div class="city-list-box">
                    <div class="city-list-1">
                        <div key="all" :class="areaId==='all' ? 'list1-item-check' : ''" @click="choseList1Item('all')">全部</div>
                        <div v-for="(item, index) in data" :key="item.label" :class="item.check ? 'list1-item-check' : ''" @click="choseList1Item(index)">{{ item.label }}</div>
                    </div>
                    <div class="city-list-2">
                        <div key="allvenue" :class="allVenue ? 'list1-item-check' : ''" @click="choseList2Item('all')" v-if="!single">全部门店</div>
                        <template v-if="items.length">
                            <div  v-for="(item, index) in items" :key="item.label" @click="choseList2Item(index)" :class="item.check ? 'list1-item-check' : ''">
                                {{ item.label }}
                                <p v-if="item.venueDistanceStr">距你{{ item.venueDistanceStr }}</p>
                            </div>
                        </template>
                        <footer v-else class="empty col-center-center">
                            <img src="https://img.chaolu.com.cn/MINI/icon/empty_icon.png" alt="">
                            <p>暂无可选择门店</p>
                        </footer>
                    </div>
                </div>
                <div class="city-select-boxs">
                    <div class="city-select-btns">
                        <div v-if="!single" class="city-reset-btn" @click="resetFun">重置</div>
                        <div v-if="single" class="city-chose-btn" @click="searchFun">{{ btnText }}</div>
                        <div v-else class="city-chose-btn" @click="searchFun">
                            查看{{ venueId.length > 0 ? venueId.length + '个' : '全部' }}门店
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {
    Popup, PullRefresh,
} from 'vant';

export default {
    name: 'clCityVenue',
    // props: ["data", "popShow", "type", 'chose-item', 'single'], // single 是否单选
    props: {
        choseItem: {
            type: Object,
            default() {return {}}
        },
        popShow: {
            type: Boolean,
            default: false
        },
        single: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: 'venue'
        },
        btnText: {
            type: String,
            default: '确认'
        },
        currentCityId: {
            type: [String, Number],
            default: ''
        },
        canUseVenue: {
            type: Array,
            default() {return []}
        },
    },
    components: {
        "van-popup": Popup,
    },
    data: function () {
        return {
            tyoeObj: {
                city: '城市',
                venue: '门店'
            },
            provinceId: '',
            cityId: '',
            areaId: '',
            venueId: [],
            data: [],
            items: [],
            listName: '',
            total: null,
            provinceName: '',
            cityName: '',
            areaName: '',
            venueName: '',
            popShow2: false,


            initStoreList: [],
            initCityList: [],
        };
    },
    computed: {
        allVenue() {
            return !this.items.some(x => x.check === false)
        }
    },
    watch: {
        currentCityId() {
          console.log('hiehiehei');
           this.getCityAndVenue()
        },
        popShow(val) {
            this.popShow2 = this.popShow
        },
        popShow2(val) {
            if (!val) {
                this.$emit('update:popShow', val)
            } else {
                this.getCityAndVenue()
            }
        },
        canUseVenue(venues) {
          console.log(venues, '-----');
            this.getCityAndVenue()
        }
    },
    methods: {
        dealCanUseVenue(venuesArr) {
            this.data = JSON.parse(JSON.stringify(this.initStoreList))
            if(venuesArr?.length && this.initStoreList.length){
                // venuesArr = venuesArr.slice(0, 5)
                // 筛选能使用的门店供选择
                this.data.forEach(row => {
                    let venues = []
                    row.children.forEach(r => {
                        let item = (venuesArr || []).find(v => v == r.venueId || v.venueId == r.venueId)
                        if(item){
                            venues.push(r)
                        }
                    })
                    row.children = venues
                })
                this.getList(this.data)
            }else if(this.initStoreList.length){
                this.getList(this.data)
            }
        },
        // 获取城市门店
        getCityAndVenue() {
            if(this.initCityList.length) {
                this.setCityAndStore()
                return false
            }
            this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top', {
                lat: this.choseItem.lat,
                lng: this.choseItem.lng,
            }).then(res => {
                if (res.code == 1) {
                    this.cityAndVenue = []
                    this.initCityList = res.data[0].children
                    this.setCityAndStore()
                }
            })
        },
        setCityAndStore() {
            if (this.currentCityId) {
                let _province = this.initCityList.find(x => {
                    return x.children.some(y => y.id === this.currentCityId)
                })
                this.choseItem.cityId = this.currentCityId
                this.choseItem.provinceId = _province.id
                this.initCityList.forEach((value, index) => {
                    let _result = value.children.find(y => y.id === this.currentCityId)
                    if (_result) {
                        this.data = _result.children
                        this.dealCanUseVenue(this.canUseVenue)
                        this.initStoreList = JSON.parse(JSON.stringify(_result.children))
                    }
                })
            }
        },
        getList(val) {
            if (this.choseItem !== undefined && val.length !== 0) {
                if (this.type === 'venue') {
                    var {areaId, venueId} = this.choseItem
                    this.areaId = areaId
                    this.venueId = venueId || []
                    this.venueId = JSON.parse(JSON.stringify(this.venueId))
                    this.areaChoseFun(areaId)
                    this.venueChoseFun(venueId)
                }
            }
        },
        provinceChoseFun(provinceId) {
            provinceId = provinceId || undefined
            for (var i = 0; i < this.data.length; i++) {
                var item = this.data[i]
                if (provinceId === item.id) {
                    item.check = true
                    this.items = item.children
                    this.provinceName = item.label
                    this.listName = item.label
                    this.total = 0
                } else {
                    item.check = false
                }
            }
        },
        cityChoseFun(cityId) {
            cityId = cityId || undefined
            for (var i = 0; i < this.items.length; i++) {
                var item = this.items[i]
                if (cityId === item.id) {
                    console.log('item', item.label)
                    item.check = true
                    this.cityName = item.label
                } else {
                    item.check = false
                }
            }
            if (cityId) {
                this.total = 1
            }
        },
        areaChoseFun(areaId) {
            areaId = areaId || undefined
            if (!areaId) {
                this.choseList1Item('all')
                return
            }
            for (var i = 0; i < this.data.length; i++) {
                var item = this.data[i]
                if (areaId === item.id) {
                    item.check = true
                    this.items = item.children
                    this.areaName = item.label
                    this.listName = item.label
                } else {
                    item.check = false
                }
            }
        },
        venueChoseFun(venueId) {
            console.log(venueId)
            venueId = venueId || []
            for (var i = 0; i < this.items.length; i++) {
                var item = this.items[i]
                if (venueId.some(x => x == item.venueId)) {
                    item.check = true
                    // this.venueName =item.label
                    if(this.single){
                        this.venueName = item.name
                    }
                } else {
                    item.check = false
                }
            }
            if (venueId) {
                this.total = 1
            }
        },
         choseList1Item(index) {
            //一级选择框
            if (index !== 'all') {
                for (var i = 0; i < this.data.length; i++) {
                    this.data[i].check = false;
                }
                this.data[index].check = true;
                this.total = this.data[index].areaCount
                this.listName = this.data[index].name
                if (this.type === 'city') {
                    this.provinceId = this.data[index].cityId || ''
                    this.provinceName = this.data[index].name
                    this.cityId = ''
                    this.cityName = '全部城市'
                } else if (this.type === 'venue') {
                    this.areaId = this.data[index].id || ''
                    this.areaName = this.data[index].label
                    // this.venueId = ''
                    // this.venueName = '全部门店'
                }
                this.items = this.data[index].children
            } else {
                console.log('choseList1Item all')
                for (let i = 0; i < this.data.length; i++) {
                    this.data[i].check = false;
                }
                this.areaId = 'all'
                this.items = []
                this.data.forEach((val) => {
                    this.items.push(...val.children)
                })
                // 存在经纬度手动排序下
                if(this.choseItem.lat && this.choseItem.lng) {
                    this.items = this.items.sort((a,b) => a.venueDistance - b.venueDistance)
                }
            }
            this.$forceUpdate();
        },
        choseList2Item(index) {
            if (index === 'all') {
                if (this.items.some(x => x.check === false)) {
                    this.items = this.items.map(x => {
                        x.check = true
                        return x
                    })
                } else {
                    this.items = this.items.map(x => {
                        x.check = false
                        return x
                    })
                }
            } else if (this.single) {
                this.venueId = []
                this.data.forEach((value, index) => {
                    value.children.forEach((value2) => {
                        value2.check = false
                    })
                })
                this.items[index].check = !this.items[index].check
            } else {
                this.items[index].check = !this.items[index].check
            }
            this.venueId = []
            this.data.forEach((value, index) => {
                value.children.forEach((value2) => {
                    if (value2.check) {
                        this.venueId.push(value2.venueId)
                        if(this.single){
                            this.venueName = value2.name
                        }
                    }
                })
            })
            this.$forceUpdate();
        },
        cancelFun() {
            setTimeout(() => {
              this.$emit("closefun", false);
            }, 500);
        },
        resetFun() {
            this.choseList1Item(0);
            this.choseList2Item(0);
            var param = {}, choseName = ''
            if (this.type === 'city') {
                param = {
                    provinceId: '',
                    cityId: '',
                    provinceName: '',
                    cityName: '',
                    areaId: '',
                    areaName: '',
                    venueId: '',
                    venueName: ''
                }
                choseName = '全部城市' + '(' + this.data[0].areaCount + ')'
            } else {
                param = {areaId: '', areaName: '', venueId: [], venueName: ''}
                choseName = '全部门店'
                this.venueId = []
                this.data.forEach((value, index) => {
                    value.children.forEach((value2) => {
                        value2.check = false
                    })
                })
            }
            this.$emit("popfun", false, param, choseName)
        },
        searchFun() {
            var param = {}, choseName = ''
            param = {areaId: this.areaId, areaName: this.areaName, venueId: this.venueId, venueName: this.venueName}
            // choseName = (this.venueId?this.venueName:'')
            choseName = (this.venueId.length > 0 ? '选中门店' + '(' + this.venueId.length + ')' : '全部门店')
            console.log('this.venueId.', this.venueId)

            if(!this.venueId?.length || !this.venueId[0]) {
                this.$toast('请选择门店！')
                return false
            }

            // 传参数给父级组件显示
            this.$emit("popfun", false, param, choseName)
        },
    },
};
</script>

<style scoped lang="less">
.empty{
    height: 100%;
    img{
        width: 200px;
        height: 200px;
    }
    p{
          margin-top: 20px;
          font-size: 26px;
          line-height: 40px;
          color: #6C727A;
      }
}
.f16 {
    font-size: 1rem;
}

.fw7 {
    font-weight: 700;
}

.mt25 {
    margin-top: 1.5625rem
}

.pl12 {
    padding-left: 0.75rem /* 12/16 */
}

.city-popup {
    min-height: 31.25rem;
    border-radius: 0.75rem 0.75rem 0 0;
    /*padding-top: 1.625rem  !* 26/16 *!;*/
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}

.van-popup__close-icon {
    color: #3c454e;
}

.van-popup__close-icon--top-right {
    top: 1.625rem /* 26/16 */;
    right: 0.75rem /* 12/16 */;
}

.city-list-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem /* 0/16 */ 0.75rem /* 12/16 */;
    margin-top: 0.9375rem /* 15/16 */;
    display: flex;
}

.city-list-2::-webkit-scrollbar,
.city-list-1::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.city-list-1 {
    margin-right: 1.0625rem /* 17/16 */;
    max-height: 23rem /* 368/16 */;
    height: 23rem /* 368/16 */;
    overflow: scroll;
}

.city-list-1 > div {
    width: 6.625rem /* 106/16 */;
    height: 92px;
    font-size: 24px;
    margin-bottom: 0.625rem /* 10/16 */;
    background: #f5f5f5;
    border-radius: 8px;
    line-height: 88px;
    text-align: center;
    overflow: hidden;
    border: 2px solid #f5f5f5;
    box-sizing: border-box;
}

.list1-item-check {
    //background: #ffde00 !important;
    border-color: #242831 !important;
    font-weight: bold;
}

.city-list-2 {
    flex: 1;
    max-height: 23rem /* 368/16 */;
    height: 23rem /* 368/16 */;
    overflow: scroll;
}

.city-list-2 > div {
    width: 100%;
    height: 112px;
    font-size: 24px;
    padding-left: 0.75rem /* 12/16 */;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 0.625rem /* 10/16 */;
    position: relative;
    border: 2px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
        margin-top: 16px;
        line-height: 20px;
        color: #3C454E;
        font-size: 20px;
        font-weight: normal;
    }
}

.city-check-img {
    width: 1.25rem /* 20/16 */;
    position: absolute;
    top: 1.125rem /* 18/16 */;
    right: 0.625rem /* 10/16 */;
}

.city-select-boxs {
    width: 100%;
    height: 4.5rem /* 72/16 */;
    /*background: pink;*/
    position: relative;
}

.city-select-btns {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 28px 22px;

}

.city-reset-btn {
    width: 6.75rem /* 108/16 */;
    height: 2.75rem /* 44/16 */;
    margin-right: 0.875rem /* 14/16 */;
    border: 1px solid #dddddd;
    border-radius: 0.25rem /* 4/16 */;
    text-align: center;
    line-height: 2.625rem /* 42/16 */;
    font-size: 0.875rem /* 14/16 */;
    box-sizing: border-box;
}

.city-chose-btn {
    background: #ffde00;
    border-radius: 0.25rem /* 4/16 */;
    flex: 1;
    border: 1px solid #ffde00;
    text-align: center;
    height: 88px;
    font-weight: bold;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
