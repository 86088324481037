export default{
    data(){
        return{
            header_color:'#fff',
            header_title:'',
            header_background:'#ffffff00',
            header_opacity:1
        }
    },
    beforedestroy(){
        this.$dom.removeEventListener('scroll', this.scrollFunction)
    },
    methods:{
        bindHeaderScroll(dom,max=100,callback){
            if(isNaN(max)||max<50){return}
            this.$dom=dom;
            const half=max/2;
            this.scrollFunction=()=> {
                const st=dom==window?(document.body.scrollTop||document.documentElement.scrollTop):dom.scrollTop;
                // console.log(st);
                if(st>max){
                    if(this.header_opacity!=1||this.header_background=='#ffffff00'){
                        this.header_title=document.title;
                        this.header_color='#333';
                        this.header_background='#fff';
                        this.header_opacity=1;
                    }
                }else{
                    this.header_opacity=Math.abs(half-st)/half;
                    if(st>half){//后一半
                        this.header_title=document.title;
                        this.header_color='#333';
                        this.header_background='#fff';
                    }else{//前一半
                        this.header_title='';
                        this.header_color='#fff';
                        this.header_background='#ffffff00';
                    }
                }
                // console.log('lls', document.title);
                callback&&callback(st,st>half)
            }
            this.$dom.addEventListener('scroll', this.scrollFunction)
        }
    }
}
