import { gotoAppPage, appOpenWeb, gotoCoachDetail, appType, gotoOrderList, goMyMoney, goMiniRecommendedCoach, goVipCenter, gotoVenue } from '../../../lib/appMethod'
import wx from 'weixin-js-sdk'

export default function(obj) { // obj: 服务端返回的跳转对象
  switch (obj.type) {
    case '1':
      gotoAppPage(2, '') // 充值
      break;
    case '3':
    case '1001':
      gotoAppPage(3, '') // 团课主页
      break;
    case '4':
    case '1002':
      gotoAppPage(1, '') // 私教主页
      break;
    case '8':
      gotoCoachDetail(obj.extendJson.teacherId) // 教练主页
      break;
    case '9':
      gotoOrderList() // 订单中心
      break;
    case '10':
      goVipCenter() // 超鹿会员
      break;
    case '11':
      goMyMoney() // 余额明细
      break;
    case '14':
      gotoVenue(1) // 门店列表
      break;
    case '20':
      gotoAppPage(5, '') // 礼品卡商城
      break;
    case '21':
      appOpenWeb(obj.extendJson.title, obj.extendJson.url) // 新开web页
      break;
    case '26':
      gotoAppPage(17, '') // 越练越便宜
      break;
    case '31':
      gotoAppPage(13, '') // 私教全部课程
      break;
    case '1005':
    case '3000':
      gotoAppPage(8, '') // 小班课首页
      break;
    case '3002':
      gotoAppPage(14, '') // 小班课-每日排课
      break;
    case '3006':
      goMiniRecommendedCoach() // 小班课推荐教练页
      break;
    case '3007':
      gotoAppPage(15, '') // 快速约课
      break;
    case '3008':
      gotoAppPage(16, '') // 意见反馈
      break;
    default:
      break;
  }
  // app className的跳转方式
  if (obj.className) {
    const appTypeStr = appType()
    switch (appTypeStr) {
      case 'ios':
        window.webkit.messageHandlers.CustomViewController.postMessage({ "className": obj.className, data })
        // ios的data格式就是一个对象
        break;
      case 'and':
        App.intentToActivity(JSON.stringify({
          "className": obj.className,
          "data": data
          // 安卓的data格式需要一个list，例如：
          // [{
          //   "key":"extra_venue_id",
          //   "value": venueId
          // }]
        }))
        break;
      default:
        wx.navigateTo({
          url: obj.className,
        })
        break;
    }
  }
}