<template>
	<van-popup v-model="show" :safe-area-inset-bottom="true" position="bottom" :close-on-click-overlay="false"
		:close-icon="baseImgUrl + 'close.png'" class="pop" :lock-scroll="true" :closeable="true" round @close="closeFun">
		<div class="title">
			<img :src="baseImgUrl + 'line.png'" alt="">
			<span>我的优惠券</span>
		</div>
		<div class="tips">本次大促活动仅发放礼品卡优惠券</div>
		<div class="c-box">
			<div class="coupon-list" v-if="list.length != 0" ref="boxRef">
				<div>
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getCouponList"
						:immediate-check="false">
						<div class="item" v-for="(item) in list" :key="item.id">
							<div class="item-i">
								<div class="item-r">
									<img class="arrow" @click.stop="openTips(item)" :class="{ 'rotate': item.active }"
										src="https://img.chaolu.com.cn/MINI/icon/bottom.png" alt="">
									<div class="coupon-name ellipsis">{{ item.voucherName }}</div>
									<div class="type-name">
										<span class="row-center-center" v-for="i in item.typeText.split(',')">{{ i }}</span>
									</div>
									<div class="coupon-date coupon-time">{{ item.validity }}</div>
									<div class="coupon-date">详细使用说明</div>
								</div>
								<div class="item-l col-center-center">
									<div><span>¥</span>{{ item.discount }}</div>
									<span dir="rtl" class="limit-int">满{{ item.spendMoney }}元可用</span>
								</div>
							</div>
							<div class="instructions"
								:class="{ 'active': item.active, 'close': item.active !== undefined && !item.active }">
								<p>{{ item.remark }}</p>
							</div>
						</div>
					</van-list>
				</div>
			</div>
			<div v-else class="empty">
				<img :src="baseImgUrl + 'empty.png'" alt="">
				<p>暂无优惠券</p>
			</div>
		</div>
	</van-popup>
</template>
<script>

export default {
	data() {
		return {
			baseImgUrl: 'https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/',
			show: false,
			type: '',
			page: 1,
			loading: false,
			finished: false,
			p: {},
			list: []
		};
	},
	methods: {
		openTips(v) {
			v.active = !v.active
			this.$forceUpdate()
		},
		showFun(d) {
			this.p = d
			this.show = true
			this.getRefsh()
		},
		changeType(choseType) {
			this.type = choseType
			this.getRefsh()
		},
		getRefsh() {
			this.page = 1
			this.list = []
			this.finished = false
			this.getCouponList()
		},
		getCouponList() {
			let params = { ...this.p, page: this.page, rows: 10, type: this.type }
			this.$axios.post(`${this.baseURLApp}/user_voucher/queryUserAllVoucherInShop`, params).then(res => {
				this.loading = false
				this.page++
				this.list = this.list.concat(res.data)
				if (res.data.length < 10) {
					this.finished = true
				}
				if (this.page == 2 && res.data.length > 0) {
					setTimeout(() => {
						this.$refs.boxRef.scrollTop = 0;
					}, 0);
				}
			})
		},
		closeFun() {
			this.show = false
			this.$emit('close');
		}
	}
};
</script>
<style lang="less" scoped>
/deep/.van-popup__close-icon--top-right {
	top: 50px;
}

.pop {
	background-color: #F5F5F5;

	.tips {
		font-size: 24px;
		text-align: center;
		padding: 14px 0px;
	}

	.title {
		height: 116px;
		background: linear-gradient(180deg, rgba(255, 93, 71, 0.3) 0%, rgba(245, 245, 245, 0) 100%);
		border-radius: 24px 24px 0px 0px;
		font-size: 36px;
		font-weight: 600;
		text-align: center;
		padding-top: 49px;
		box-sizing: border-box;
		position: relative;

		>img {
			width: 200px;
			position: absolute;
			left: 37%;
			bottom: 17px;
			z-index: 7;
		}

		span {
			z-index: 10;
			position: relative;
		}
	}

	.type {
		padding: 22px 24px;

		>span {
			font-size: 24px;
			padding: 13px 16px;
			border-radius: 4px;
			background: #fff;
			margin-right: 12px;
			display: inline-block;
			margin-bottom: 14px;

			&.act {
				background: #FFDE00;
				font-weight: 600;
			}
		}
	}

	.c-box {
		height: 60vh;

		.empty {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding-top: 210px;

			img {
				width: 320px;
				height: 320px;
			}

			p {
				margin-top: 12px;
				font-size: 28px;
				color: #999;
			}
		}

		.coupon-list {
			height: 60vh;
			overflow-y: scroll;
		}

		.coupon-list::-webkit-scrollbar {
			display: none;
		}


	}
}


.instructions {
	//height: 0;
	width: 692px;
	margin: -16px auto 0;
	overflow: hidden;
	max-height: 0;
	background-color: white;
	box-sizing: border-box;
}

.instructions p {
	padding: 24px;
	font-size: 20px;
	color: #9AA1A9;
	line-height: 32px;
	white-space: pre-wrap;
}

.instructions.active {
	animation: open .5s forwards;
}

.instructions.close {
	animation: close .5s forwards;
}

@keyframes open {
	0% {
		max-height: 0;
	}

	100% {
		max-height: 120px;
		overflow-y: scroll;
	}
}

@keyframes close {
	0% {
		max-height: 120px;
	}

	100% {
		max-height: 0;
		overflow-y: hidden;
	}
}

.content {
	padding: 0 0 32px;
	box-sizing: border-box;
	overflow-y: scroll;
}


.pay-btn {
	width: 686px;
	height: 96px;
	background: #FFDE00;
	border-radius: 8px;
	color: #242831;
	font-size: 32px;
	margin: 36px auto 38px;
}

.coupon-item {
	color: #242831;
	font-size: 24px;
	font-weight: bold;
	padding: 32px 0 18px;
	border-top: 1px solid #E6E6E6;
}

.coupon-item-tips {
	color: #242831;
	font-size: 24px;
}

.coupon {
	height: 100px;
	box-sizing: border-box;
	//border-top: 1px solid #E6E6E6;
	border-bottom: 1px solid #E6E6E6;
	background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-right.png") no-repeat center right;
	background-size: 24px 24px;
	padding-right: 34px;
	margin-top: 12px;

	.coupon-price-red {
		color: #FB732E;
	}

	.s2 {
		font-family: BebasNeueBold;
		font-size: 32px;
		font-weight: bold;
		color: #F03C18;
		margin-right: 4px;
	}

	.s3 {
		font-family: BebasNeueBold;
		color: #F03C18;
		font-size: 40px;
		font-weight: bold;
	}
}

.coupon-title {
	font-weight: bold;
	color: #242831;
	font-size: 36px;
	//padding-left: 56px;
	height: 112px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 12px;
	//background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-left.png") no-repeat center left;
	//background-size: 40px 40px;
}



.price {
	padding: 28px 0 32px;
}

.price>div .s1 {
	text-decoration: line-through;
	color: #6C727A;
	line-height: 28px;
	font-size: 24px;
	margin-right: 16px;
}

.price>div .s2 {
	font-family: BebasNeueBold;
	color: #242831;
	font-size: 24px;
	font-weight: bold;
}

.price>div .s3 {
	font-family: BebasNeueBold;
	color: #242831;
	font-size: 40px;
	font-weight: bold;
}

.left {
	font-size: 24px;
	color: #242831;
	line-height: 28px;
}


.pay-type {
	color: #242831;
	font-size: 24px;
	font-weight: bold;
	padding: 32px 0 8px;
}

.wechat,
.alipay {
	background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/normal.png") no-repeat center right;
	background-size: 40px 40px;
	padding: 24px 0;

	&.active {
		background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/active.png");
	}

	div {
		display: flex;
		align-items: center;
	}

	img {
		width: 40px;
		height: 40px;
		margin-right: 16px;
	}
}

.item {
	margin-bottom: 16px;
	position: relative;

	.active-item {
		background: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/select_icon.png") center center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		left: 30px;
		top: 14px;
		width: 48px;
		height: 48px;
		box-sizing: border-box;
	}
}

.item-i {
	display: flex;
	justify-content: space-between;
	height: 270px;
	//align-items: center;
	padding: 32px;
	box-sizing: border-box;
	background-size: 100% 100%;
	background-image: url(https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_bg.png);
}

.gary {
	.item-r .coupon-name {
		color: #787A80;
	}

	.item-i {
		background-image: url(https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_bg_no.png);
	}

	.type-name span {
		background: #FF9C80;
	}

	.item-r .coupon-date.coupon-time {
		color: #787A80;
	}
}

.item-l {
	width: 192px;
	height: 100%;
	color: white;
	font-size: 20px;
	flex-grow: 1;
}

.item-l>div:first-child {
	font-family: BebasNeueBold;
	font-size: 80px;
}

.item-l>div:first-child span {
	font-size: 36px;
}

.item-l>.use {
	margin-top: 28px;
	background-color: #fff;
	color: #FF7047;
	padding: 6px 22px;
	border-radius: 100px;
	font-size: 20px;
}

.item-r {

	box-sizing: border-box;
	padding-left: 26px;
	position: relative;
	width: 440px;
	flex-shrink: 0;
}

.item-r .coupon-tabs {
	display: flex;
	flex-wrap: wrap;
	margin-top: 12px;


}

.item-r .coupon-tabs span {
	padding: 4px 8px;
	font-weight: bold;
	color: #FB732E;
	font-size: 18px;
	border: 1px solid #FB732E;
	border-radius: 4px;
	margin-bottom: 8px;
	margin-right: 8px;
}

.item-r .coupon-date {
	margin-top: 38px;
	font-size: 20px;
	line-height: 23px;
	color: #9AA1A9;
	position: absolute;
	left: 26px;
	bottom: 8px;

	&.coupon-time {
		bottom: 48px;
		color: #242831;
	}
}

.item-r .coupon-name {
	line-height: 28px;
	font-weight: bold;
	font-size: 28px;
	padding-top: 24px;
}

.type-name {
	display: flex;
	align-items: center;
	margin-top: 16px;

	span {
		height: 32px;
		padding: 0 16px;
		background: #FF7047;
		border-radius: 16px;
		color: #FFFFFF;
		font-size: 20px;

		&+span {
			margin-left: 16px;
		}
	}
}

.goodsNum {
	margin-top: 48px;
}

.item-r .arrow {
	position: absolute;
	right: 4px;
	bottom: -8px;
	width: 24px;
	height: 24px;
	transition: transform .5s;
	padding: 20px;
}

.arrow.rotate {
	transform: rotateZ(180deg);
}

.c-container {
	margin-top: 48px;
}


.no-tips {
	margin: 64px auto 20px;
	font-size: 24px;
	line-height: 24px;
	font-weight: bold;
	color: #9AA1A9;
	text-align: center;
}

.limit-int {
	color: #fff;
	font-size: 20px;
	margin-left: 6px;
	letter-spacing: 1px;
	margin-top: 12px;
	line-height: 20px;
}
</style>

