<template>
  <div class="timer row-center-center f24" :style="`color: ${timeObj.subfieldNameColor};`">
    {{
      actStatus == "notin"
        ? "距活动开始"
        : actStatus == "in"
        ? "距活动结束"
        : actStatus == "end"
        ? "活动已结束"
        : ""
    }}
    <template v-if="actStatus == 'notin' || actStatus == 'in'">
      <van-count-down :style="`color: ${timeObj.subfieldNameColor};`" @finish="timeFinish" :time="time">
        <template #default="timeData">
          <div class="t-box row-center-center f26 fw6">{{ timeData.days }}</div>
          天
          <div class="t-box row-center-center f26 fw6">{{ timeData.hours }}</div>
          时
          <div class="t-box row-center-center f26 fw6">{{ timeData.minutes }}</div>
          分
          <div class="t-box row-center-center f26 fw6">{{ timeData.seconds }}</div>
          秒
        </template>
      </van-count-down>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      actStatus: ''
    }
  },
  props: {
    timeObj: {
      type: Object,
    },
    status: {
      type: String
    }
  },
  computed: {
    time() {
      let d = this.timeObj
      return d.diffStartTime || d.diffEndTime;
    },
  },
  watch: {
    status:{
			handler(v) {
        this.actStatus = v
      },
			immediate: true
		}
  },
  methods: {
    timeFinish() {
      this.$emit('timeFinish')
    },
  },
};
</script>
<style lang="less">
.timer {
  color: #fff;
  line-height: 42px;
  margin: 20px 0 12px;
  height: 42px;

  .t-box {
    width: 44px;
    height: 40px;
    background: #fff;
    border-radius: 4px;
    margin: 0 12px;
    color: #27292d;
    display: inline-flex;
  }

  .van-count-down {
    font-size: 24px;
    color: #fff;
  }
}
</style>
